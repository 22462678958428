@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');

@font-face {
  font-family: 'UniversLTStd-BlackEx';
  src: local('UniversLTStd-BlackEx'), url(./fonts/Univers/UniversLTStd-BlackEx.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-BlackExObl';
  src: local('UniversLTStd-BlackExObl'), url(./fonts/Univers/UniversLTStd-BlackExObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Bold';
  src: local('UniversLTStd-Bold'), url(./fonts/Univers/UniversLTStd-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Cn';
  src: local('UniversLTStd-Cn'), url(./fonts/Univers/UniversLTStd-Cn.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-CnObl';
  src: local('UniversLTStd-CnObl'), url(./fonts/Univers/UniversLTStd-CnObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Ex';
  src: local('UniversLTStd-Ex'), url(./fonts/Univers/UniversLTStd-Ex.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-ExObl';
  src: local('UniversLTStd-ExObl'), url(./fonts/Univers/UniversLTStd-ExObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Light';
  src: local('UniversLTStd-Light'), url(./fonts/Univers/UniversLTStd-Light.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightCn';
  src: local('UniversLTStd-LightCn'), url(./fonts/Univers/UniversLTStd-LightCn.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightCnObl';
  src: local('UniversLTStd-LightCnObl'), url(./fonts/Univers/UniversLTStd-LightCnObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightObl';
  src: local('UniversLTStd-LightObl'), url(./fonts/Univers/UniversLTStd-LightObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightUltraCn';
  src: local('UniversLTStd-LightUltraCn'), url(./fonts/Univers/UniversLTStd-LightUltraCn.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Obl';
  src: local('UniversLTStd-Obl'), url(./fonts/Univers/UniversLTStd-Obl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-ThinUltraCn';
  src: local('UniversLTStd-ThinUltraCn'), url(./fonts/Univers/UniversLTStd-ThinUltraCn.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-UltraCn';
  src: local('UniversLTStd-UltraCn'), url(./fonts/Univers/UniversLTStd-UltraCn.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlack';
  src: local('UniversLTStd-XBlack'), url(./fonts/Univers/UniversLTStd-XBlack.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlackEx';
  src: local('UniversLTStd-XBlackEx'), url(./fonts/Univers/UniversLTStd-XBlackEx.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlackExObl';
  src: local('UniversLTStd-XBlackExObl'), url(./fonts/Univers/UniversLTStd-XBlackExObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlackObl';
  src: local('UniversLTStd-XBlackObl'), url(./fonts/Univers/UniversLTStd-XBlackObl.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd';
  src: local('UniversLTStd'), url(./fonts/Univers/UniversLTStd.otf) format('opentype');
}
