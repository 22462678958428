@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap);
#App {
  background-color: #000;
  font-family: UniversLTStd;
}

.web3modal-modal-lightbox {
  z-index: 10 !important;
}

button:focus {
  outline: none;
}

@font-face {
  font-family: 'UniversLTStd-BlackEx';
  src: local('UniversLTStd-BlackEx'), url(../../static/media/UniversLTStd-BlackEx.ef9a6679.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-BlackExObl';
  src: local('UniversLTStd-BlackExObl'), url(../../static/media/UniversLTStd-BlackExObl.c68d8c16.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Bold';
  src: local('UniversLTStd-Bold'), url(../../static/media/UniversLTStd-Bold.8d6e7b74.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Cn';
  src: local('UniversLTStd-Cn'), url(../../static/media/UniversLTStd-Cn.ed864895.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-CnObl';
  src: local('UniversLTStd-CnObl'), url(../../static/media/UniversLTStd-CnObl.ce1925fc.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Ex';
  src: local('UniversLTStd-Ex'), url(../../static/media/UniversLTStd-Ex.b76751ce.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-ExObl';
  src: local('UniversLTStd-ExObl'), url(../../static/media/UniversLTStd-ExObl.53ad3bf0.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Light';
  src: local('UniversLTStd-Light'), url(../../static/media/UniversLTStd-Light.67a74ea3.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightCn';
  src: local('UniversLTStd-LightCn'), url(../../static/media/UniversLTStd-LightCn.f742f8d2.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightCnObl';
  src: local('UniversLTStd-LightCnObl'), url(../../static/media/UniversLTStd-LightCnObl.fbcb0a89.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightObl';
  src: local('UniversLTStd-LightObl'), url(../../static/media/UniversLTStd-LightObl.ef7f851c.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-LightUltraCn';
  src: local('UniversLTStd-LightUltraCn'), url(../../static/media/UniversLTStd-LightUltraCn.a0309f5b.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-Obl';
  src: local('UniversLTStd-Obl'), url(../../static/media/UniversLTStd-Obl.f7d0872c.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-ThinUltraCn';
  src: local('UniversLTStd-ThinUltraCn'), url(../../static/media/UniversLTStd-ThinUltraCn.4e4f75be.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-UltraCn';
  src: local('UniversLTStd-UltraCn'), url(../../static/media/UniversLTStd-UltraCn.7e48f33e.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlack';
  src: local('UniversLTStd-XBlack'), url(../../static/media/UniversLTStd-XBlack.a2ebc3e0.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlackEx';
  src: local('UniversLTStd-XBlackEx'), url(../../static/media/UniversLTStd-XBlackEx.f6975258.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlackExObl';
  src: local('UniversLTStd-XBlackExObl'), url(../../static/media/UniversLTStd-XBlackExObl.d9094f19.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd-XBlackObl';
  src: local('UniversLTStd-XBlackObl'), url(../../static/media/UniversLTStd-XBlackObl.2e4837fe.otf) format('opentype');
}
@font-face {
  font-family: 'UniversLTStd';
  src: local('UniversLTStd'), url(../../static/media/UniversLTStd.7df669ff.otf) format('opentype');
}

.speech-bubble {
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  padding: 5px;
}

.speech-bubble-left:after {
  content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 53px solid transparent;
	border-right-color: rgba(0, 0, 0, 0.8);
	border-left: 0;
	border-bottom: 0;
	margin-top: -26.5px;
	margin-left: -53px;
}

.speech-bubble-right:after {
  content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 53px solid transparent;
	border-left-color: rgba(0, 0, 0, 0.8);
	border-right: 0;
	border-bottom: 0;
	margin-top: -26.5px;
	margin-right: -53px;
}

.speech-bubble-bottom:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 53px solid transparent;
	border-top-color: rgba(0, 0, 0, 0.8);
	border-bottom: 0;
	border-right: 0;
	margin-left: -26.5px;
	margin-bottom: -53px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

